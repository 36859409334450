import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useCallback } from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import "./ProjectTimeline.scss"

import DatePicker from "react-datepicker";

import {numberOnly} from "../common/utils"

const ProjectTimeline = () => {
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState();
  const [sumOfTime, setSumOfTime] = useState(0);
  const [form_values, setFormValues] = useState({time_to_hold:120});

  const handlechange = event => {
    // remove initial empty spaces
    if(numberOnly(event.target.value)){
      setFormValues({...form_values,[event.target.name]: ''})
    } else{
      setFormValues({...form_values,[event.target.name]: event.target.value})
    }
    
  }

  useEffect(() =>{
    //setSumOfTime(event.target.value);
    //console.log("form_values", form_values);
    var sum_month = 0;
    for (let value of Object.values(form_values)) {
      sum_month += (value !== '' ? parseInt(value) : 0);
    }

    setSumOfTime(sum_month);

  },[form_values]);

  return (
    <React.Fragment>
      <div className="personal-application text-center">
        <Container>
          <Row>
            <Col>
              <h2 className="form-title">
                 <div className="animated">
                  We can also help plan your project timeline with a simple tool that takes the following into considerations:
                 </div>
              </h2>
               <div className="animated">
                <Form className="application-form text-left">
                  <Row>
                    <Form.Group className="col-md-4">
                      <Form.Label className="annual-txt">Close of Escrow</Form.Label>
                      
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        showTimeSelect={false}
                        minDate={new Date()}
                        placeholderText={"Saturday 16, May 2021"}
                        dateFormat="MMMM d, yyyy"
                        className={'form-control'}
                        required={false}
                        autocomplete="off"
                        name={'close_of_escrow'}
                        autoComplete={'' + Math.random()}                
                        // onChange={handlechange}
                        value={startDate}
                        wrapperClassName={"form-control"}
                        
                      />
                    </Form.Group>

                    <Form.Group className="col-md-4">
                      <Form.Label className="annual-txt">Planning</Form.Label>
                      <Form.Control type="text" placeholder="Enter the number of months" value={form_values.planning} name={'planning'} onChange={handlechange} />
                    </Form.Group>
                    <Form.Group className="col-md-4">
                      <Form.Label className="annual-txt">Sitework</Form.Label>
                      <Form.Control type="text" placeholder="Enter the number of months" value={form_values.sitework} name={'sitework'}  onChange={handlechange} />
                    </Form.Group>
                    <Form.Group className="col-md-4">
                      <Form.Label className="annual-txt">Construction Phase</Form.Label>
                      <Form.Control type="text" placeholder="Enter the number of months" value={form_values.construction} name={'construction'} onChange={handlechange} />
                    </Form.Group>
                    <Form.Group className="col-md-4">
                      <Form.Label className="annual-txt">Lease-up Phase</Form.Label>
                      <Form.Control type="text" placeholder="Enter the number of months" value={form_values.lease} name={'lease'} onChange={handlechange} />
                    </Form.Group>
                    <Form.Group className="col-md-4">
                      <Form.Label className="annual-txt">Time to Hold Prior to Sale</Form.Label>
                      <Form.Control type="text" placeholder="Enter the number of months" defaultValue={form_values.time_to_hold} name={'time_to_hold'} readOnly />
                    </Form.Group>

                    <div className="timeline-total col-md-12">
                      <h4>Total Project Time</h4>
                      <p>{sumOfTime}   {parseInt(sumOfTime) > 0 && <span>{sumOfTime > 1 ? "Months" : "Month"}</span>}</p>
                    </div>

                  </Row>
                </Form>
               </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectTimeline
