import React from "react"
import {
  Container,
  Row,
  Col,
} from "react-bootstrap"
import "../InsightsPeoples/FilterBlock.scss"
import AccordionBlock from "../../components/AccordionBlock/AccordionBlock"

const FaqCareerList = (props) =>{
    return(
        <div className="filter-block insights video_filter faq-career-list">
             <Container>
        <Row>
          <Col lg="12">
        
             <div className="animated">
                <div className={"faq_list"}>
                {
                  props.FaqItems && props.FaqItems.length > 0 && props.FaqItems.map((item, index) => {
                   
                      return(
                        <>                  
                          <h1>{item.Category}</h1>  
                          {item.Faq_List && item.Faq_List.length > 0 && <AccordionBlock AccordionItems={item.Faq_List} activeId={index === 0 ? "0" : '-1'} /> }
                        </>
                      )
                  })
                }
                </div>
             </div>
          </Col>
        </Row>
      </Container>
        </div>

    )
}
export default FaqCareerList