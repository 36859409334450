import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import {isMobile} from "react-device-detect";
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick"
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import PlayVideo from "../../Play/PlayVideo"

// Images
import './GetStarted.scss';

const InteriorBlock = (props) => {
  // Slider
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const [isPlay, setPlay] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');
  const playVideo = (url) => {
    setPlay(true);
    setYoutubeId(url)
  }
  return (
    <div className="article-wrap interor-block">
      {
        !props.Content_Left ? (
          <Row className="topspace align-items-lg-top">
            <Col lg="7" className={'align-self-start left-side'}>
               <div className="animated">
                {props.Images && props.Images.length > 0 &&
                  <div className="article-img market-video left">
                    {props.Images.length > 1 ?
                      (<Slider {...settings}>
                        {
                          props.Images.map((image) => {
                            return <img classNames={""} src={image.url} />
                          })
                        }
                      </Slider>) : (
                        <>
                          <picture>
                            <img classNames={""} src={props.Images[0].url} />
                          </picture>
                          {
                            props.Video_URL &&
                            <strong to="#" className="play-btn"
                              onClick={() => { playVideo(props.Video_URL); }}
                            >
                              <span className="btn-anim d-flex align-items-center justify-content-center">
                                <span className="text-center">
                                  <i className="play-icon"></i>
                                </span>
                              </span>
                            </strong>
                          }
                        </>
                      )
                    }
                  </div>
                }
                {
                  props.Pinterest_Link &&
                  <div className="elfsight-widgets">
                    <div className={props.Pinterest_Link}></div>
                  </div>

                }



               </div>
            </Col>

            <Col lg="5" className="ml-lg-auto">
               <div className="animated">
                <div  className={`${(isMobile) ? 'article_block_text left pl-0' : 'article_block_text right' }`}>
                  <h2>{props.Title}</h2>
                  <p>{props.Content && HTMLReactParser(props.Content)}</p>
                  {props.CTA_Label && <Link to={`${props.CTA_Link.Secondary_URL}`} onClick={() => { localStorage.setItem('typeVal', props.Title) }} className="grey_nobg_btn">{props.CTA_Label}</Link>}
                </div>
               </div>
            </Col>
          </Row>
        ) : (
          <Row className="row-space align-items-lg-top">
            <Col lg="7" className="order-lg-2 ml-lg-auto align-self-start right-side">
               <div className="animated">
                {props.Images && props.Images.length > 0 &&
                  <div className="article-img market-video right">
                    {props.Images.length > 1 ?
                      (<Slider {...settings}>
                        {
                          props.Images.map((image) => {
                            return <img classNames={""} src={image.url} />
                          })
                        }
                      </Slider>) : (
                        <>
                          <picture>
                            <img classNames={""} src={props.Images[0].url} />
                          </picture>
                          {
                            props.Video_URL &&
                            <strong to="#" className="play-btn"
                              onClick={() => { playVideo(props.Video_URL); }}
                            >
                              <span className="btn-anim d-flex align-items-center justify-content-center">
                                <span className="text-center">
                                  <i className="play-icon"></i>
                                </span>
                              </span>
                            </strong>
                          }
                        </>
                      )
                    }
                  </div>
                }
                {
                  props.Pinterest_Link &&
                  <div className="elfsight-widgets">
                    <div className={props.Pinterest_Link}></div>
                  </div>
                }
               </div>
            </Col>

            <Col lg="5" className="order-lg-1">
               <div className="animated">
                <div className="article_block_text left pl-0">
                  <h2>{props.Title}</h2>
                  <p>{props.Content && HTMLReactParser(props.Content)}</p>
                  {props.CTA_Label && <Link to={`${props.CTA_Link.Secondary_URL}`} onClick={() => { localStorage.setItem('typeVal', props.Title) }} className="grey_nobg_btn">{props.CTA_Label}</Link>}
                </div>
               </div>
            </Col>
          </Row>
        )
      }

      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={youtubeId}
        isAutoPlay={1}
      />
    </div>)
}



export default InteriorBlock