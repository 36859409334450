import { Link } from "@StarberryUtils";
import React, { useState, useEffect, createRef } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import PlayVideo from "../../Play/PlayVideo"
import _ from "lodash";
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import "./Grid-block.scss"
import $ from "jquery"
import HTMLReactParser from 'html-react-parser';

const GridBlock = (props) => {




  return (
    <React.Fragment>
      <div className={`grid-block `}>
        <Container>
        <Row>
          {props.Grid_List.map((item,i) => {
            return (

              <Col className="col-12 col-sm-6 col-lg-4 col-md-4" key={i}>
                <div className="list">
                  <img src={item.Image.url} />
                  <h4>{item.Title}</h4>
                  <p className="desc">{item.Content && HTMLReactParser(item.Content)} </p>
                  <Link to={item.CTA_Link?.Primary_URL ? item.CTA_Link?.Primary_URL : "#"} className=""><span>{item.CTA_Label}</span></Link>
                </div>
              </Col>

            )
          })}
        </Row>
        </Container>
      </div>


    </React.Fragment>
  )
}


export default GridBlock