import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';

import { ImageModule, SourceBlock } from '../../../modules/Image_Module';

// Images
import './List-block.scss';

const ListBlock = (props) => {

  return (
    <div className="article-wrap mortgage-list">
      <Container>
        {
          props.Image_Postion === "Left" ? (
            <Row className="topspace align-items-lg-center">
              <Col lg="5" className={'align-self-start'}>
                 <div className="animated">
                  <div className="article-img">
                    <picture>
                      <img classNames={""} src={props.Image.url} />
                    </picture>
                  </div>
                 </div>
              </Col>

              <Col lg="6" className="ml-lg-auto">
                 <div className="animated">
                  <div className="article_block_text pl-0">
                    <h2>{props.Service_title}</h2>
                    <div class="block-content">{props.Content && HTMLReactParser(props.Content)}</div>

                    {props.CTA_Label && <Link to={props.ActionLink} className="grey_nobg_btn"><span>{props.CTA_Label}</span></Link>}
                  </div>
                 </div>
              </Col>
            </Row>
          ) : (
            <Row className="row-space align-items-lg-center">
              <Col lg="5" className="order-lg-2 ml-lg-auto align-self-start">
                 <div className="animated">
                  <div className="article-img">
                    <picture>
                      <img classNames={""} src={props.Image.url} />
                    </picture>
                  </div>
                 </div>
              </Col>

              <Col lg="6" className="order-lg-1">
                 <div className="animated">
                  <div className="article_block_text pl-0">
                    <h2>{props.Service_title}</h2>
                    <div class="block-content">{props.Content && HTMLReactParser(props.Content)}</div>
                    {props.CTA_Label && <Link to={props.ActionLink} className="grey_nobg_btn"><span>{props.CTA_Label}</span></Link>}
                  </div>
                 </div>
              </Col>
            </Row>
          )
        }

      </Container>
    </div>)
}



export default ListBlock