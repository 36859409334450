import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"

import { Link, animateScroll as scroll } from "react-scroll";
import { Helmet } from "react-helmet";

import "../InsightsPeoples/FilterBlock.scss"
import AccordionBlock from "../../components/AccordionBlock/AccordionBlock"

const FilterBlock = props => {

  const [filterCat, setFilterCat] = useState('All');
  

  var uniqueCat = [];
  props.FaqItems && props.FaqItems.length > 0 && props.FaqItems.map((item, index) => {
    if (uniqueCat.indexOf(item.Category) === -1) {
      if(item.Category){
        uniqueCat.push(item.Category)
      }
      
    }
  });

  var faqschemaList = [];
  props?.FaqItems?.map((faq, i)=>{
      faq?.Faq_List.map((faqitem,i) =>{
        faqschemaList.push({
            "@type": "Question",
              "name": `${faqitem?.Title}`,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": `${faqitem?.Content}`
              }
        })
      })
  })

  const filterCateogy = (category) =>{
    //console.log("category", category);
    setFilterCat(category);
  }
  
  //console.log("FaqItems", props.FaqItems);
  return (
    <div className="filter-block insights video_filter">
      <Helmet>
        <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": ${JSON.stringify(faqschemaList, null, 2)}
        }`}

      </script>
      </Helmet>
      <Container>
        <Row>
          <Col lg="12">
            {
              uniqueCat && uniqueCat.length > 1 &&
              <div className="filter-block-group">
                 <div className="animated">
                  <div className="filter-link">
                    <span className={`filter-link-btn faq ${filterCat === 'All' && 'filter-link-btn--active'}`} onClick={()=>setFilterCat('All')}>All</span>
                    {
                      uniqueCat.map((item, index) => {
                        return(
                          <Link to={item} className={`filter-link-btn faq ${item === filterCat && 'filter-link-btn--active'}`} spy={true} smooth={true} offset={-120} onClick={()=>filterCateogy(item)}>{item}</Link>
                        )
                      })
                    }
                  </div>
                 </div>
              </div>
            }
            
             <div className="animated">
                <div className={"faq_list"}>
                {
                  props.FaqItems && props.FaqItems.length > 0 && props.FaqItems.map((item, index) => {
                    if(filterCat === 'All'){
                      return(
                        <>
                          {/* <h3 id={item.Category}>{item.Category}</h3>                       */}
                          <h3>{item.Category}</h3>  
                          {item.Faq_List && item.Faq_List.length > 0 && <AccordionBlock AccordionItems={item.Faq_List} activeId={index === 0 ? "0" : '-1'} /> }
                        </>
                      )
                    }
                    if(item.Category === filterCat){
                      return(
                        <>
                          {/* <h3 id={item.Category}>{item.Category}</h3>                       */}
                          <h3>{item.Category}</h3>
                          {item.Faq_List && item.Faq_List.length > 0 && <AccordionBlock AccordionItems={item.Faq_List} activeId={index === 0 ? "0" : '-1'} /> }
                        </>
                      )
                    } 
                    
                  })
                }
                </div>
             </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FilterBlock