import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useCallback } from "react"
import {
    Button,
    Navbar,
    Nav,
    Form,
    Container,
    FormControl,
    Row,
    Col,
} from "react-bootstrap"
import ContactDetails from "./ContactDetails/ContactDetails"
import ContactMap from "./ContactMap/ContactMap"
import googleReview2 from "../../images/home/google-reviews-2.svg"
import "./ContactUs.scss"

import wordsToNumbers from 'words-to-numbers';

// styles

// markup
const ContactUs = (props) => {

    //console.log("data", data);

    var testimonial_count = props.testimonials ? props.testimonials.length : 0;
    var rating_count = 0;
    props.testimonials && props.testimonials.length > 0 && props.testimonials.map((item, ind)=>{
        rating_count += parseInt(wordsToNumbers(item.starRating));
    })
    var rating_avg = rating_count/testimonial_count;

    var contact_office = props.Modules && props.Modules.choose_office;

    return (

        <React.Fragment>
            <div className="contact_container">
                <ContactDetails {...props} />
                {contact_office.Latitude && contact_office.Longtitude && 
                    <ContactMap contact_office={contact_office} />
                }
                
            </div>
        </React.Fragment>
    )
}

export default ContactUs