import React, {useState, useEffect} from "react"

import { Link } from "@StarberryUtils";
 
const ContactCard = (props) => { 

  return (
    
      <div className="contact-card" > 
                <figure>
                  {props.cardImg && props.cardImg.url && 
                  <img src={props.cardImg.url}  alt={ props.cardImg.alternativeText} /> } 
                </figure>

                <div className="info">
                    <h6 className="card-title">{props.cardTitle}</h6> 
                </div>
              
            

      </div>
     
  )
}

export default ContactCard