import React from "react";
import HTMLReactParser from 'html-react-parser';

const DealsTextBlock = (props) => {
    return (
        <>
            {props.representative && HTMLReactParser(props.representative)}
            {props.description &&
                <div class="notes">
                    {props.description && HTMLReactParser(props.description)}
                </div>
            }

        </>
    );
};
export default DealsTextBlock;