import React from "react"
import { useMatch } from "@reach/router"
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../components/Home/Home.scss"
import '../scss/custom.scss';
import '../scss/custom-responsive-style.scss';

import CommonTemplate from './common-template';
import InteriorTemplate from './interior-template';
import MortgageTemplate from './mortgage-template';
import ProjectTemplate from './project-template';


import get from 'lodash/get'
import { useStaticQuery, graphql } from 'gatsby'

export default (props) => {

  var GQLPage = props.data.glstrapi?.article;
  var GQLModules = props.data.glstrapi?.article?.Add_Modules;
  var GlobalModules = props.data.glstrapi?.globalModule;

  if (GQLPage?.Layout === "Interior_Landing_Page") {
    return (
      <InteriorTemplate GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={props.location} />
    )
  }
  // else if (GQLPage.Layout === "Project_Landing_Page") {
  //   return (
  //     <ProjectTemplate location={props.location} />
  //   )
  // }
  else if (GQLPage?.Layout === "Mortgage_Landing_page") {
    return (
      <MortgageTemplate GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={props.location} />
    )
  } else {
    return (
      <CommonTemplate GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={props.location} />
    )
  }


}

export const pageQuery = graphql`
  query DeafultQuery($articleId: ID!) {

    glstrapi {
      globalModule {
        Insights_Block {
          Small_Text
          Content
          CTA_1_Label
          CTA_1_Link
          Title
        }
      }

      articles {
        Add_Modules {
          __typename
          
          ... on GLSTRAPI_ComponentModulesTableuAccordianBlock {
            id
            Tableu_Accordian {
              Accordian_Content
              Accordian_Heading
              Tableu_Embed_Code
              id
            }
          }
        }
      }

      article(id: $articleId, publicationState: LIVE) {
        id
        Alias
        Content_Heading_Text
        Content
        Layout
        Meta_Title
        Meta_Description
        Page_CSS_Class
        Custom_CSS_Class
        Pagename
        Publish
        Select_Popular_Search
        imagetransforms
        Header_Banner_Image {
          id
          url
          alternativeText
        }
        Header_Banner_Video
        Play_Button
        popular_search {
          Module_Name
          ModuleType {
            ... on GLSTRAPI_ComponentModulesContentBlock {
              id
              Title
              Content
            }
          }
        }

        Add_Modules {

          __typename

          ... on GLSTRAPI_ComponentModulesVideoPlaylist {
            id
            Playlist_Item {
              Playlist_ID
              Playlist_Title
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentsPlaylistIncludeVideo {
            id
            Playlist_Videos {
              Playlist_ID
              Playlist_Title
              Include_Video_ID {
                Video_ID
              }
              
            }
          }

          ... on GLSTRAPI_ComponentComponentsExcludeVideos {
            id
            Exclude_Videos {
              Exclude_Youtube_Video_ID
              id
            }
          }
          
          ... on GLSTRAPI_ComponentModulesChooseOffice {
            id
            choose_office {
              Office_Name
              Address
              Email
              Direct_Phone
              Latitude
              Longtitude
              Image {
                alternativeText
                url
              }
              Meta_Description
              Meta_Title
              Name
              Office_Phone
              Phone
              URL
              id
              Opening_Hours {
                Timing
                Week_Day
                id
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsMultifamilyTableauBlock {
            id
            Multifamily_Tableau_Section {
              Content
              Tableau_Alignment
              Tableau_Embed_Link
              Title
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentsMultifamilyHeader {
            id
            Form_Heading
            Header_Content
            Header_Title
            Show_Enquire_Form
          }

          ... on GLSTRAPI_ComponentComponentsToolkit {
            id
            Title
            Snippet_Box {
              CTA_1_Label
              CTA_1_URL
              Content
              Icon {
                url
                alternativeText
              }
              Title
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentsValuation {
            id
            Title
            Content
            Toolkit {
              id
              Title
              Content
              Snippet_Box {
                CTA_1_Label
                CTA_1_URL
                Content
                Title
                id
                Icon {
                  alternativeText
                  url
                }
              }
            }
            Tiles_Block {
              CTA_1_Label
              CTA_1_URL
              Content
              id
              Tile_Image {
                alternativeText
                url
              }
              Title
            }
          }

          ... on GLSTRAPI_ComponentModulesTableuAccordianBlock {
            id
            Tableu_Accordian {
              Accordian_Content
              Accordian_Heading
              Tableu_Embed_Code
              id
            }
          }

          ... on GLSTRAPI_ComponentModulesValuationThankyou {
            id
            Title
            Content
            CTA_1_URL
            CTA_1_Label
          }
          ... on GLSTRAPI_ComponentModulesValautionAccordianBlock {
            id
            Title
            CTA_1_URL
            CTA_1_Label
            Accordian_Block {
              id
              Title
              Content
            }
          }

          ... on GLSTRAPI_ComponentModulesHeaderBanner {
            id
            Header_Title
            Content
            CTA_2_URL
            CTA_2_Label
            CTA_1_URL
            CTA_1_Label
            Banner_Image {
              alternativeText
              url
            }
            Show_Banner_Google_Review
          }

          ... on GLSTRAPI_ComponentModulesContentBlock {
            id
            Title
            Content
          }
          ... on GLSTRAPI_ComponentComponentsStepContentBlock {
            id
            Steps {
              Content
              Slug
              Title
            }
          }

          ... on GLSTRAPI_ComponentComponentsGraphsBlock {
            id
            Title
            Content
            Add_Tabs {
              CTA_1_Label
              CTA_1_URL
              Tab_Content
              Tab_Name
              Tab_Title
              id
              Graph_Image {
                Embed_Code
                id
                Title
                Graph_Image {
                  alternativeText
                  url
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesVideo {
            id
            Background_Image {
              url
              alternativeText
            }
            Video_Label
            Embed_Video_URL
            Upload_Video {
              alternativeText
              url
            }
          }


          ... on GLSTRAPI_ComponentModulesShowProperty {
            id
            Show_Property
            Title
            Stb_List {
              Stb_List_Item
              Stb_List_Title
              id
            }
          }
          ... on GLSTRAPI_ComponentModulesShowInsights {
            id
            Show_Insights
            Filter_by_Tag
          }
          ... on GLSTRAPI_ComponentModulesShowGoogleReviews {
            id
            Show_Google_Review
          }
          ... on GLSTRAPI_ComponentModulesCollections {
            id
            Select_Collection
          }
          ... on GLSTRAPI_ComponentModulesShowTimeline {
            id
            Show_Timeline
          }

          ... on GLSTRAPI_ComponentModulesContactDetail {
            id
            Contact_Detail
          }

          ... on GLSTRAPI_ComponentModulesIntro {
            id
            Intro_Title
            Intro_Content
            Intro_Text
            Choose_Office {
              Name
              Direct_Phone
              Image {
                url
              }              
            }
            Intro_CTA_Link
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                url
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
          }

          ... on GLSTRAPI_ComponentComponentsSubmenuBlock {
            id
            Add_Item {
              Title
              id
              Alternative_Link
              URL {
                id
                Primary_URL
                Label
                imagetransforms
                Tile_Image {
                  url
                  alternativeText
                }
                Main_Parent {
                  Primary_URL
                }
                Sub_Parent {
                  Primary_URL
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesAccordionBlock {
            Add_Accor_Item {
              Content
              id
              Title
            }
          }

          ... on GLSTRAPI_ComponentModulesFaqBloack {
            id
            Category
            Faq_List {
              Content
              Title
              id
            }
          }

          ... on GLSTRAPI_ComponentModulesRightsideModule {
            id
            modules {
              Module_Name
              ModuleType {
                ... on GLSTRAPI_ComponentModulesRightSideBlock {
                  id
                  Title
                  CTA_1_Label
                  CTA_2_Label
                  CTA_1_URL
                  CTA_2_URL
                  Content
                  Embed_Video_URL
                  RightsideImage:Image {
                    url
                    alternativeText
                  }
                  Choose_Peoples {
                    Name
                    Email
                    URL
                    Image:Tile_Image {
                      url
                      alternativeText
                    }
                    Phone
                    Office_Phone:Phone
                    Whatsapp_No:WhatsApp
                  }
                }
              }
            }
          }
          
          ... on GLSTRAPI_ComponentModulesSnaggingTilesBlock {
            id
            Snagging_Tiles {
              CTA_1_Label
              CTA_1_URL
              Content
              Title
              id
              Image {
                alternativeText
                url
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesGetStarted {
            id
            CTA_1_Label
            CTA_1_URL
            Image_Alignment
            Image {
              alternativeText
              url
            }
            Get_Started_Title:Title
            Get_Started_Content:Content
            Get_Started_Intro_Content:Get_Started_Intro_Content
            List_Items {
              Item_Text
            }
            Videos_List {
              Sort
              Tile_Image {
                  url
              }
              Video_Orignal {
                  url
              }
              Video_Title
              Video_Type
              Video_Url
            }
          }


          ... on GLSTRAPI_ComponentModulesBottomBlock {
            id
            Bottom_Title:Title
            CTA_1_URL
            CTA_1_Label
            Bottom_Content:Content
            Module_Class
          }


          ... on GLSTRAPI_ComponentModulesShowCommunityList {
            id
            Show_Community_List
          }

          ... on GLSTRAPI_ComponentModulesAudioModule {
            id
            Audio_Title
            Embed_Video_Link
            Audio_File {
              url
              alternativeText
            }
          }
          ... on GLSTRAPI_ComponentComponentsInteriorBlock {
            id
            CTA_Label
            CTA_Link{
              Primary_URL
              Secondary_URL
            }
            Content
            Content_Left
            Images {
              alternativeText
              url
            }
            Pinterest_Link
            Title
            Video_URL
          }
          ... on GLSTRAPI_ComponentModulesCollectionBlock {
            id
            Collection_Name
            Title
            Content
          }
          ... on GLSTRAPI_ComponentModulesIntroCopyBlock {
            id
            Intro_Copy_Title
            Intro_Copy_Content
          }
          ... on GLSTRAPI_ComponentComponentsGridBlock {
            id
            Grid_List {
              Title
              Content
              Image {
                alternativeText
                url
              }
              CTA_Label
              CTA_Link {
                Primary_URL
                Secondary_URL
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsServiceContentBlock {
            id
            Service_title : Title
            Content
            Image_Postion
            Image {
              alternativeText
              url
            }
            CTA_Label
            ActionLink: CTA_Link 
          }
        }
      }
    }
}`
