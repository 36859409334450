import { Link } from "@StarberryUtils";
import React,{useEffect,useState, useRef} from "react";
import {Button, Navbar, Nav,Form, Container,FormControl,Row,Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import VideoSlider from "../VideoSlider/VideoSlider";
import { ImageModule, SourceBlock } from '../../modules/Image_Module';

// Images
import  './GetStarted.scss';

const ArticleBlock = ( props ) => {

  return(
    <>
    {/* { props.Get_Started_Block?.Videos_List ?
    <div className="career-page-video-module-section">
        <h2 className="left-image-align-h2">{props.Get_Started_Block.Get_Started_Title}</h2>
                  {
                    props.Get_Started_Block?.Videos_List && props.Get_Started_Block?.Videos_List.length > 0 &&
                    <VideoSlider data={props.Get_Started_Block}  />
                  }
    </div> : ""} */}
    <div className="article-wrap">
      <Container>      
        {
          props.Get_Started_Block.Image_Alignment === "Left" ? (
            <Row className="topspace align-items-lg-center">
              <Col lg={12}>
              {props.pageAlias === "careers" ?
               <div className="career-page-video-module-section">
                   <h2 className="career-page-h2">{props.Get_Started_Block.Get_Started_Title}</h2>
                  {
                    props.Get_Started_Block?.Videos_List && props.Get_Started_Block?.Videos_List.length > 0 &&
                    <VideoSlider data={props.Get_Started_Block}  />
                  }
              </div> : ""}
              </Col>
              
              <Col lg={props.pageAlias === "careers" ? "4" : "5"}  className={`${props.pageAlias === "careers" ? "" : "align-self-start" }`}>
                 <div className="animated">
                <div className="image-left-align article-img">
                <picture>
                  <ImageModule classNames={""} ImageSrc={props.Get_Started_Block.Image} />
                </picture>
                </div>
                 </div>
              </Col>

              <Col lg={props.pageAlias === "careers" ? "8" : "6"}  className="ml-lg-auto">
                 <div className="animated"> 
                <div className="article_block_text">
                  { props.pageAlias !== "careers" &&<h2>{props.Get_Started_Block.Get_Started_Title}</h2>}
                  
                  <p>{props.Get_Started_Block.Get_Started_Content  && HTMLReactParser(props.Get_Started_Block.Get_Started_Content)}</p>
                  <p>{props.Get_Started_Block.Get_Started_Intro_Content  && HTMLReactParser(props.Get_Started_Block.Get_Started_Intro_Content)}</p>
                  {
                    props.Get_Started_Block.List_Items.length > 0 && (
                      <ul>
                        {
                          props.Get_Started_Block.List_Items.map((item, text) => {
                            return(<li className={`${props.Get_Started_Block.Get_Started_Title === "Financial & Investment Strategy" ? 'col-12' : ''}`}><i className="icon-check"></i>{item.Item_Text}</li>)
                          })
                        }
                      </ul>
                    )
                  }
                  {props.Get_Started_Block.CTA_1_Label && <Link to={props.Get_Started_Block.CTA_1_URL} className="grey_nobg_btn">{props.Get_Started_Block.CTA_1_Label}</Link>}                  
                </div>
                 </div>
              </Col>
            </Row>
          ) : (
            <Row className="row-space align-items-lg-center">
              <Col lg={props.pageAlias === "careers" ? "4" : "5"}  className={`order-lg-2 ml-lg-auto ${props.pageAlias === "careers" ? "" : "align-self-start" }`}>
                 <div className="animated">
                <div className="article-img">
                <picture>
                  <ImageModule classNames={""} ImageSrc={props.Get_Started_Block.Image} />
                </picture>
                </div>
                 </div>
              </Col>

              <Col lg={props.pageAlias === "careers" ? "8" : "6"}  className="order-lg-1">
                 <div className="animated"> 
                <div className="article_block_text pl-0 testt">
                  <h2>{props.Get_Started_Block.Get_Started_Title}</h2>
                  <p>{props.Get_Started_Block.Get_Started_Content && HTMLReactParser(props.Get_Started_Block.Get_Started_Content)}</p>
                  <p>{props.Get_Started_Block.Get_Started_Intro_Content  && HTMLReactParser(props.Get_Started_Block.Get_Started_Intro_Content)}</p>

                  {
                    props.Get_Started_Block.List_Items.length > 0 && (
                      <ul>
                        {
                          props.Get_Started_Block.List_Items.map((item, text) => {
                            return(<li className={`${props.Get_Started_Block.Get_Started_Title === "Financial & Investment Strategy" ? 'col-12' : ''}`}><i className="icon-check"></i>{item.Item_Text}</li>)
                          })
                        }
                      </ul>
                    )
                  }
                  {props.Get_Started_Block.CTA_1_Label && <Link to={props.Get_Started_Block.CTA_1_URL} className="grey_nobg_btn">{props.Get_Started_Block.CTA_1_Label}</Link>}
                </div>
                 </div>
              </Col>
            </Row>      
          )
        }
          
         </Container> 
    </div>
    </>)
}



export default ArticleBlock