import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Link } from "@StarberryUtils";
import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import FileField from './elements/file'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function } from "../common/utils"
import { PropertyBaseAPI } from "../../queries/api_links"

import axios from "axios"
import * as qs from "query-string"

import $ from 'jquery'

function ValuationForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");
  const [file, setFile] = useState([]);

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  
  const [startDate, setStartDate] = useState();

  //console.log("valuationType",props.valuationType);

  var date_value = new Date();
  var current_date = date_value.getFullYear() +'-'+ (date_value.getMonth() >= 9 ? date_value.getMonth() + 1 : '0'+(date_value.getMonth() + 1)) + '-' + date_value.getDate();


  var fields = ([
      {
        element: "config",
        formname: props.formName ? props.formName : 'Valuation',
        form_type: props.formName ? props.formName.toLowerCase() : 'valuation',
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "valuation_user",
        email_temp_admin: props.valuationType === "1" ? "cost_to_valuation_admin" : props.valuationType === "2" ? "comparable_sales_to_valuation_admin" : "income_property_valuation_admin",
        email_server_func: "valuation",
        event_tracking: props.formName ? props.formName.toLowerCase() : 'valuation',
        page_url: "/valuation",
        email_subject_user: props.formName+' Request',
        email_subject_admin: props.formName+' Request'
      },
      {
        grpmd: "12",
        label: `What is your first name?`,
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z]+(\\s+[a-zA-Z]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: `What is your last name?`,
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: false,
        patternchk: "^[a-zA-Z]+(\\s+[a-zA-Z]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "What is your e-mail address?",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18"

      },
      {
        grpmd: "12",
        label: "What is your phone number?",
        placeholder: "+971 12345678",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "content_b-18"

      },
      {
        grpmd: "12",
        label: `Where is the property location?`,
        placeholder: "",
        name: "property_location",
        type: "text",
        element: "input",
        patternchk: "^[a-zA-Z]+(\\s+[a-zA-Z]+)*$",
        required: false,
        labelClass: "content_b-18"
      }
    
    ]);

    props.valuationType === "1" ? (
        fields = ([...fields,{
            grpmd: "12",
            label: `What was the total construction cost(QR)?`,
            placeholder: "",
            name: "construction_cost",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: `Have you applied any upgrades? How much it cost you(QR)?`,
            placeholder: "",
            name: "upgarde_cost",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: `How old is the property?`,
            placeholder: "",
            name: "old_property",
            type: "text",
            element: "input",
            //patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18",
            maxlength:"20"
        },
        {
            grpmd: "12",
            label: `Have you implemented any site improvements? How much it cost you(QR)?`,
            placeholder: "",
            name: "improvements_cost",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: `How much is the land value(QR)? It's ok to estimate for now?`,
            placeholder: "",
            name: "land_value",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
        }])
    ) : (
        fields = ([...fields,{
            grpmd: "12",
            label: `What is the size of your property in SQM?`,
            placeholder: "",
            name: "property_sqm",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
          },
          {
            grpmd: "12",
            label: `How many bedrooms is the property?`,
            placeholder: "",
            name: "no_of_bedrooms",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18",
            maxlength:"2"
          },

          props.valuationType === "3" && (
          {
            grpmd: "12",
            label: `What is the current or expected monthly rent(QR)?`,
            placeholder: "",
            name: "monthly_rent",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
          }),    
          {
            grpmd: "12",
            label: `How much did you buy the property for?`,
            placeholder: "",
            name: "buy_property",
            type: "text",
            element: "input",
            patternchk:"^[0-9]+$",
            required: false,
            labelClass: "content_b-18"
          },
          // {
          //   grpmd: "12",
          //   label: `When was the last Purchase Date?`,
          //   placeholder: "",
          //   name: "purchase_date",
          //   type: "date",
          //   element: "input",
          //   required: true,
          //   labelClass: "content_b-18",
          //   max: current_date
          // }
          {
            grpmd: "12",
            label: "When was the last purchase date?",
            placeholder: "",
            name: "purchase_date",
            type: "valuation_date_time",
            element: "input",
            // min: today,
            // max: maxday.toString(),
            required: false,
            patternchk: "",
            class: "form-control",
            labelClass: "content_b-18"
          },
      ])
    )

    fields = ([...fields, 
      {
        grpmd: "12",
        label: "Please upload the floor plan.",
        placeholder: "Click to select a file",
        name: "file",
        type: "file",
        element: "file",
        required: false,
        accept:".pdf,.doc,.jpg,.png,.jpeg",
        fieldClass: "form-control-browsefile custom custom-file",
        labelClass: "content_b-18",
        id:"custom-file",
        show_formats:true,
        multiple:false
      },
      {
        type: "hidden",
        element: "input",
        name:"cvname"
      },
      {
        grpmd: "12",
        label: "Please upload property pictures.",
        placeholder: "Click to select a file",
        name: "property_picture",
        type: "file",
        element: "file",
        required: false,
        accept:".jpg,.png,.jpeg",
        fieldClass: "form-control-browsefile custom custom-file",
        labelClass: "content_b-18",
        id:"custom-file2",
        show_formats:true,
        multiple:true
      },       
      {
        type: "hidden",
        element: "input",
        name:"property_picture_name"
      }, 
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper col-md-12 col-sm-12 col-xs-12"
      },
      {
        text: 'By clicking Submit, I agree to The Pearl Gates <a href="/terms-and-conditions" className="content-link">Terms & Conditions</a>',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    if(event.target.name === "telephone"){
      var valid_phone = phoneNumberValidation(event.target.value);
      if(valid_phone){
        event.target.value = valid_phone;
      } else{
        event.target.value = '';
      }
    } else{
      event.target.value = event.target.value.trimStart()
    }
  }

  const handleAttachment = event => {
    var fileItem2 = event.target.files[0]; // accesing file
    var fileItem = event.target.files;
    var d = document.getElementById(event.target.id);

    //console.log("files__ fileItem", fileItem, fileItem2)
    //alert(d.value);

    var file_name = [], file_item_arr = [];

    if(fileItem.length === 1){
      $("#"+event.target.id).next('.custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));
      setFile({...file, [event.target.name]: fileItem2});
    } else{
      for(var i=0; i < fileItem.length; i++ ){
        file_name.push(fileItem[i].name);
        file_item_arr.push(fileItem[i]);
      }
      $("#"+event.target.id).next('.custom-file-label').html(file_name.join(', '));
      setFile({...file, [event.target.name]: file_item_arr});
    }

  };

  // const handleAttachment = event => {
  //   const fileItem = event.target.files[0]; // accesing file
  //   var d = document.getElementById('custom-file');
  //   //alert(d.value);
  //  $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));
  //  setFile(fileItem); // storing file
  // };

  useEffect(() => {

    if (token !== '') {

    const processFromData = async () => {
      formvalues['g-recaptcha-response'] = token;

      let formData = new FormData();

      //console.log("formvalues_file", formvalues.files);
      //console.log("formvalues_file 2", file.file);

      if (formvalues && formvalues.files && formvalues.files.length > 0) {
        //formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        //formData.append('files.property_pictures', formvalues.files, formvalues.files.name); // appending file
        
        for(var i=0; i < formvalues.files.length; i++ ){
          formData.append('files.property_pictures', formvalues.files[i], formvalues.files[i].name); 
        }        
      } else if(formvalues.files){
        formData.append('files.property_pictures', formvalues.files, formvalues.files.name);
      }

      if(file && file.file){
        formData.append('files.attachment', file.file, file.file.name);
      }


      //console.log("formvalues==> 1", formvalues);

      formData.append('data', JSON.stringify(formvalues));

      //console.log("formData", formData);

    

      postFormData(formData).then(async apiRes => {
        //console.log("apiRes", apiRes);
        // if (apiRes?.attachment?.url) {
        //   // If image from externall url, use that
        //   if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
        //     formvalues['file'] = `${apiRes.attachment.url}`;
        //     formvalues['cvname'] = `${apiRes.attachment.url}`;
        //   } else { // or we consider it is loading from api url
        //     formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
        //     formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
        //   }
        // }

        if(apiRes){
          if (apiRes.attachment && apiRes.attachment.length > 0) {
            // If image from externall url, use that
            if (apiRes.attachment[0].url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment[0].url}`;
              formvalues['cvname'] = `${apiRes.attachment[0].url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
            }
          }

          if (apiRes.property_pictures && apiRes.property_pictures.length > 0) {
            // If image from externall url, use that
            var file_urls = [];
            for(var i = 0; i < apiRes.property_pictures.length; i++){
              if (apiRes.property_pictures[i].url.match(/(http|https):\/\//g)) {
                file_urls.push(apiRes.property_pictures[i].url);
              } else { // or we consider it is loading from api url
                file_urls.push(process.env.GATSBY_STRAPI_FORM_URL+apiRes.property_pictures[i].url);
              }

              formvalues['property_picture'] = file_urls.join(' , ');
              formvalues['property_picture_name'] = file_urls.join(' , ');
            }
            
          }
          

          //console.log("formvalues 4", formvalues);
          window.grecaptcha.reset();
          await Mail_Function(formvalues);
          
          var purcahseDate = new Date(formvalues.purchase_date);
          var purcahseDateSplit = purcahseDate.getFullYear()+'-'+parseInt(purcahseDate.getMonth()+1)+'-'+purcahseDate.getDate();
          //console.log("query_params1", purcahseDateSplit);

          var query_params2 = "contact[RecordTypeId]=0126g000000GHBsAAO&contact[FirstName]="+formvalues.first_name+"&contact[LastName]="+formvalues.last_name+"&contact[Email_2__c]="+formvalues.email+"&contact[MobilePhone]="+formvalues.telephone+"&contact[LeadSource]=website&contact[Property_Location__c]="+formvalues.property_location+"&contact[Property_Images__c]="+formvalues.property_picture_name+"&contact[pba__ContactType_pb__c]="+formvalues.form_name.replace('&', 'and')+"&contact[New_Website__c]=true&contact[Subscribed_For_Monthly_Newsletter__c]=true&contact[pba__LeadRoutingCompleted__c]=false";

          if(formvalues.construction_cost){
            query_params2 += "&contact[Total_Construction_Costs__c]="+formvalues.construction_cost
          }
          if(formvalues.upgarde_cost){
            query_params2 += "&contact[Upgrades_How_much_it_cost_to_you__c]="+formvalues.upgarde_cost
          }
          if(formvalues.old_property){
            query_params2 += "&contact[How_old_is_the_property__c]="+formvalues.old_property
          }
          if(formvalues.old_property){
            query_params2 += "&contact[Any_site_improvement_How_much_cost_QR__c]="+formvalues.improvements_cost
          }
          if(formvalues.land_value){
            query_params2 += "&contact[Land_Value__c]="+formvalues.land_value
          }
          if(formvalues.property_sqm){
            query_params2 += "&contact[Unit_sqm__c]="+formvalues.property_sqm
          }
          if(formvalues.no_of_bedrooms){
            query_params2 += "&contact[Bedroom__c]="+formvalues.no_of_bedrooms
          }
          if(formvalues.buy_property){
            query_params2 += "&contact[Property_Buyed_Price__c]="+formvalues.buy_property
          }
          if(formvalues.purchase_date){
            query_params2 += "&contact[Last_Purchase_Date__c]="+purcahseDateSplit
          }
          if(formvalues.monthly_rent){
            query_params2 += "&contact[Expected_Current_Rent_QR__c]="+formvalues.monthly_rent
          }


          //console.log("query_params2", query_params2);

          await PropertyBaseAPI(query_params2).then(response => {
          //console.log("property_base res", response)
            if(response.status === 200){
              console.log('property_base', 'success')
              navigate('/valuation-confirmation');
            } else{
              console.log('property_base', 'status error')
              navigate('/valuation-confirmation');
            }        
          }).catch(err =>
            console.log("property_base", "error")
          );

          

          // lets send mail
          // const axiosOptions_email = {
          //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email).then(response => {
          //   console.log('mail sent!')          
          // }).catch(err =>
          //   console.log(err)
          // );
          
        }
        

      });

      formvalues['g-recaptcha-response'] = token;

      

      // const axiosOptions_netlify = {
      //   url: fields[0].page_url,
      //   method: "post",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   data: qs.stringify(formvalues),
      // }

      // axios(axiosOptions_netlify)
      //   .then(response => {
      //     console.log('data stored')
      //   })
      //   .catch(err =>
      //     console.log(err)
      //   );
      // const url = typeof window !== 'undefined' ? window.location.href : ''  
      // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
      //   fields[0].formname = 'Get mortgage help';
      // }

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': props.formLabel ? props.formLabel : ''
      });

      setShowerror(false);
      setThankyou(true);
      
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        //navigate('/valuation-confirmation');
        // setTimeout(function(){
        //   navigate('/valuation-confirmation');
        // },2000)
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    /*if((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }*/

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      json['extra'] = {"property_location": json.property_location, "construction_cost": json.construction_cost, "upgarde_cost": json.upgarde_cost, "old_property": json.old_property, "improvements_cost": json.improvements_cost, "land_value": json.land_value, "property_sqm": json.property_sqm, "no_of_bedrooms": json.no_of_bedrooms, "monthly_rent": json.monthly_rent, "buy_property": json.buy_property, "purchase_date": json.purchase_date};
      
      //console.log("files_upload", file);
      
      //json['files'] = file.file;
      json['files'] = file.property_picture;
      //json['property_pictures'] = file;

      //setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
      $('.form-control-browsefile .custom-file-label').html('Click here to select file');
      
    }
  };

  //console.log("files___", file, file && file.property_picture, file && file.file);

  return (
    <div className="form stbform val_frm">
    <div ref={myRef} />

      {showerror && <div className="alert alert-danger">
        {fields[0].error_text}
      </div>}

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}

      <Form name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={""} />
        
        <input type="hidden" name="bot-field" />

        
        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  startDate={field.type === 'valuation_date_time' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date_time' ? setStartDate : ''}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  max={field.max}
                  maxlength={field.maxlength}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ( "file" === field.element ) {
                return (
                  <>
                  <FileField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    type={field.type}
                    fieldClass={field.fieldClass}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    key={`${field.element}~${index}`}
                    accept={field.accept}
                    handlechange={handleAttachment}
                    required={field.required}
                    field_id={field.id}
                    show_formats={field.show_formats}
                    multiple={field.multiple}
                  />

                  </>
                );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
              );
            }
          })
        }
        </Form.Row>
      </Form>
    </div>
  );
}


const ValuationPage = (props) => (
  <ValuationForm {...props}/>
)

export default ValuationPage