import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';

import ContactCard from "./ContactCard"
// Style
import './Portfolio.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import { Interiors } from "../../../queries/common_use_query"

import { ImageModule } from "../../../modules/Image_Module";

const Portfolio = (props) => {
    const [interiorsData, setInteriorData] = useState([]);
    const { loading, errors, data } = Interiors();
    useEffect(() => {
        if (data?.interiorDesigns) {
            setInteriorData(data?.interiorDesigns);
        }

    }, [data])
    return (
        <div className="contact-info cards-wrap">
            <div className="section-content">
                {props.Title && <p className="highlight-text">{props.Title}
                </p>}
                {props.Content && HTMLReactParser(props.Content)}
            </div>
            <Row className={"justify-content-center"}>

                {
                    interiorsData && interiorsData.length > 0 && interiorsData.map((item, index) => {

                        return (
                            <Col md={6} lg={4} xl={3}>
                                 <div className="animated">
                                    {
                                        <Link to={item.Slug}>
                                            <ContactCard
                                                cardImg={item.Tile_Image}
                                                cardTitle={item.Title}
                                                article_id={item.id}
                                            />
                                        </Link>
                                    }

                                 </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )

}
export default Portfolio