import * as React from "react"
import MapImg from "../../../images/map-img.jpg";
import MapImgTablet from "../../../images/map-table.jpg";
import mapMobile from "../../../images/map-mobile.jpg";

import GoogleSingleMap from "../../maps/google/contact_us_map"
import "./ContactMap.scss"
// styles

// markup
const ContactMap = (props) => {

    // console.log("Office", props)

    return (

        <React.Fragment>
            <div className="contact-map-wrap">
                 <div className="animated">
                    <GoogleSingleMap lat={props.contact_office.Latitude ? parseFloat(props.contact_office.Latitude) : ''} lng={props.contact_office.Longtitude ? parseFloat(props.contact_office.Longtitude) : ''} OfficeAddress={props.contact_office.Address} OfficeName={props.contact_office.Office_Name} height={"500px"} zoom={12}/>
                 </div>
            </div>
        </React.Fragment>
    )
}

export default ContactMap