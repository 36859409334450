import { Link } from "@StarberryUtils";
import * as React from "react"
import { Row, Container, Col, Nav, Tab } from "react-bootstrap"
import Slider from "react-slick"
import HTMLReactParser from 'html-react-parser';
// Images

import { ImageModule, SourceBlock } from '../../../modules/Image_Module';

import "./PropertyOwner.scss"
// markup


const PropertyOwner = (props) => {
  return (
    <div className="property-owner-block">
      <Container>
        <Row>
          <Col>
            
            <div className="home-worth-box">

              
                {
                  props.Tiles_Blocks && props.Tiles_Blocks.length > 0 && props.Tiles_Blocks.map((item, index) => {
                    return(
                       <div className="animated">
                      <div className="home-worth-item">
                        <div className="home-worth-img">
                          <picture>
                            {item.Image ? <ImageModule classNames={""} ImageSrc={item.Image} /> : '' }
                          </picture>
                        </div>
                        <div className="home-worth-info">
                          <h2>{item.Title}</h2>
                          {item.Content && HTMLReactParser(item.Content)}
                          {item.CTA_1_URL === "snagging_form" ? 
                            <a href="javascript:;" className="btn btn-primary d-none d-md-inline-block" onClick={(e) => props.openModalForm(e, 'Snagging & Handover - '+item.CTA_1_Label)}><span>{item.CTA_1_Label}</span></a> 
                          : 
                            <Link to={item.CTA_1_URL ? item.CTA_1_URL : "#"} className="btn btn-primary">
                              <span>{item.CTA_1_Label}</span>
                            </Link>
                           }
                        </div>
                      </div>
                       </div>
                    )
                  })
                }
                
              

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PropertyOwner
