import * as React from "react"
import HomeWorthBlock from "./HomeWorthBlock/HomeWorthBlock"
import PropertyToolkit from "./PropertyToolkit/PropertyToolkit"
import "./Valuation.scss"
// styles
// markup
const Valuation = (props) => {

  //console.log("Valuation_data", props.Valuation_data);

  return (
    <>
      <div className="page-content ">      
        {props.Valuation_data && props.Valuation_data[0].Tiles_Block && props.Valuation_data[0].Tiles_Block.length > 0 && <HomeWorthBlock Title={props.Valuation_data[0].Title} Content={props.Valuation_data[0].Content} TilesBlock={props.Valuation_data[0].Tiles_Block}/>}

        {props.Valuation_data && props.Valuation_data[0].Toolkit && <PropertyToolkit Toolkit={props.Valuation_data[0].Toolkit}/>}
      </div>
    </>
  )
}

export default Valuation