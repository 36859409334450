import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import HTMLReactParser from 'html-react-parser';
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from "react-bootstrap"
import Select, { components } from "react-select";
import { useLocation } from "@reach/router";
import "./TenantCalculator.scss"
import secLogoBg2 from "../../images/form-combined/sec-logo-bg2.svg"

import { calculateMonthlyPayment } from "../../calculator/mortgage"
import { addCommas, removeNonNumeric, numericWithDecimal, CalculatorFields } from "../../components/common/utils"
import RightSideBlock from "./RightSideBlock"


const MortgageCalculator = (props) => {
  const pagelocation = useLocation()
  const [calculator_value, setCalculatorValue] = useState({ 'loan_amount': "1000000", 'interest_rate': 6.50, 'amortization': '30', 'down_payment': '0' });
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loanOption, setLoanOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ value: 'buy_to_live', label: 'Buy to live', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50 })

  const handleChange = event => {
    if (event.target.name === "interest_rate") {
      setCalculatorValue({ ...calculator_value, [event.target.name]: event.target.value > CalculatorFields.InterestSliderMax ? CalculatorFields.InterestSliderMax : numericWithDecimal(event.target.value) })
    } else {
      if (event.target.name === "amortization") {
        if (parseInt(event.target.value) > 35) {

        } else {
          setCalculatorValue({ ...calculator_value, [event.target.name]: removeNonNumeric(event.target.value) })
        }
      } else {
        setCalculatorValue({ ...calculator_value, [event.target.name]: removeNonNumeric(event.target.value) })
      }
    }
  }

  const handleChange2 = (field_name, field_value) => {
    if (field_name === "interest_rate") {
      setCalculatorValue({ ...calculator_value, [field_name]: numericWithDecimal(parseFloat(field_value).toFixed(2)) })
    } else {
      setCalculatorValue({ ...calculator_value, [field_name]: field_value > 0 ? removeNonNumeric(field_value) : 0 });
    }
  }

  const loanOptionChange = (event) => {

    setCalculatorValue({ ...calculator_value, interest_rate: event.interest_Rate })
    setSelectedOption(event)

  }


  useEffect(() => {
    setMonthlyPayment(
      calculateMonthlyPayment(
        calculator_value.loan_amount,
        calculator_value.interest_rate,
        calculator_value.down_payment,
        calculator_value.amortization
      )
    );
    let params = new URLSearchParams(pagelocation.search);
    if (params.get("action") && !loanOption) {
      setLoanOption(params.get("action"))
      let selectOption = options.filter(function (option) {
        return option.value === params.get("action");
      })
      if (selectOption) {
        setSelectedOption(selectOption)
      }
      setLoanOption(true);
    }
  }, [calculator_value]);



  var monthly_payment = Math.round(monthlyPayment);
  var valuation_payment = Math.round((monthlyPayment * selectedOption.valuation_fees) / 100);
  var management_payment = Math.round((monthlyPayment * selectedOption.management_fees) / 100);
  var loan_care_payment = Math.round((monthlyPayment * selectedOption.loan_care_fees) / 100);
  const options = [
    { value: 'buy_to_live', label: 'Buy to live', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 6.50 },
    { value: 'buy_to_let', label: 'Buy to let', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 6.50 },
    { value: 'buy_out', label: 'Buy Out', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.30, interest_Rate: 6.50 },
    { value: 'equilty_release', label: 'Equilty Release', valuation_fees: 0, management_fees: 1, loan_care_fees: 0.50, interest_Rate: 6.50 },
    { value: 'young_qataris', label: 'Young Qataris', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 6 },
    { value: 'vacant_land', label: 'Vacant Land', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 7.50 },
    { value: 'construction', label: 'Construction', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 7.50 },
    { value: 'off_plan', label: 'Off Plan', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 7.50 },
    { value: 'non_resident', label: 'Non Resident', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 6.75 },
    { value: 'no_salary', label: '40/60 - No Salary', valuation_fees: 0, management_fees: 0, loan_care_fees: 0.50, interest_Rate: 6.7 },
  ]

  return (
    <React.Fragment>
      {
        props.page &&

        <div className="tenant-calculator text-center" style={{ backgroundImage: `url(${props.page.Header_Banner_Image.url})` }}>
          <div className="calculator-inner">
            <Container>
              <Row>
                 <div className="animated">
                  <Col className="d-xl-flex">
                    <Form className="calculator-form text-left">
                      <h2 className="form-title">{props.page.Pagename}</h2>
                      <p className="form-desc">
                        {props.page.Content && HTMLReactParser(props.page.Content)}
                      </p>
                      <Form.Group>
                        <Form.Label className="annual-txt">
                          Loan Option
                        </Form.Label>
                        <div className="anmual-amount d-md-flex justify-content-between">
                          <div className=" order-2 select-group ">
                            <Select
                              isSearchable={false}
                              name={'loan_option'}
                              value={selectedOption}
                              onChange={loanOptionChange}
                              options={options}
                              required={true}
                              classNamePrefix={"select-opt"}
                            />
                          </div>

                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="annual-txt">
                          Loan Amount (QR)
                        </Form.Label>
                        <div className="anmual-amount d-md-flex justify-content-between">
                          <div className="amount-slider order-2">
                            <Slider
                              min={0}
                              max={CalculatorFields.PriceSliderMax}
                              style={{ marginTop: 22 }}
                              tooltip={false}
                              value={`${calculator_value.loan_amount}`.replace(/,/g, "")}
                              onChange={(value) => handleChange2('loan_amount', value)}
                            />
                          </div>
                          <input
                            maxlength={CalculatorFields.PriceInputMax}
                            value={addCommas(calculator_value.loan_amount)}
                            className="form-control"
                            name="loan_amount"
                            onInput={handleChange}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="annual-txt">
                          Interest Rate (%)
                        </Form.Label>
                        <div className="anmual-amount d-md-flex justify-content-between">
                          <div className="amount-slider order-2">
                            <Slider
                              min={0}
                              max={CalculatorFields.InterestSliderMax}
                              step={0.1}
                              style={{ marginTop: 22 }}
                              tooltip={false}
                              value={`${calculator_value.interest_rate}`}
                              onChange={(value) => handleChange2('interest_rate', value)}
                            />
                          </div>
                          <input
                            maxlength={CalculatorFields.InterestInputMax}
                            value={calculator_value.interest_rate}
                            name="interest_rate"
                            className="form-control"
                            onInput={handleChange}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="annual-txt">
                          Amortization (Years)
                        </Form.Label>
                        <div className="anmual-amount d-md-flex justify-content-between">
                          <div className="amount-slider order-2">
                            <Slider
                              min={0}
                              max={CalculatorFields.YearSliderMax}
                              style={{ marginTop: 22 }}
                              tooltip={false}
                              value={`${calculator_value.amortization}`}
                              onChange={(value) => handleChange2('amortization', value)}
                            />
                          </div>
                          <input
                            maxlength={CalculatorFields.YearInputMax}
                            value={calculator_value.amortization}
                            name="amortization"
                            className="form-control"
                            onInput={handleChange}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="annual-txt">
                          Down Payment (QR)
                        </Form.Label>
                        <div className="anmual-amount d-md-flex justify-content-between">
                          <div className="amount-slider order-2">
                            <Slider
                              min={0}
                              max={CalculatorFields.PriceSliderMax}
                              style={{ marginTop: 22 }}
                              tooltip={false}
                              value={`${calculator_value.down_payment}`.replace(/,/g, "")}
                              onChange={(value) => handleChange2('down_payment', value)}
                            />
                          </div>
                          <input
                            maxlength={CalculatorFields.PriceInputMax}
                            value={addCommas(calculator_value.down_payment)}
                            name="down_payment"
                            className="form-control"
                            onInput={handleChange}
                          />
                        </div>
                      </Form.Group>

                    </Form>

                    {/* ======== Rightside Block ====== */}
                    <div className="tenant-details text-left">
                      <img src={secLogoBg2} className="sec-logobg2" />
                      <div className="wrp">
                        <div className="qr-detail d-md-flex d-xl-block justify-content-between">
                          <div className="qr-wrap">
                            <span className="info-txt d-block">Your monthly payment will be</span>
                            <strong className={`qr-price d-block ${monthly_payment.toString().length > 6 ? 'font_60' : ''}`}>
                              {monthly_payment ? addCommas(monthly_payment) : 0}<sup>QR</sup>
                            </strong>
                          </div>
                          <div className="qr-wrap sub-payment">
                            <span className="info-txt d-block">Valuation Fees</span>
                            <strong className={`qr-price d-block ${valuation_payment.toString().length > 6 ? 'font_60' : ''}`}>
                              {valuation_payment ? addCommas(valuation_payment) : 0}<sup>QR</sup>
                            </strong>
                          </div>
                          <div className="qr-wrap sub-payment">
                            <span className="info-txt d-block">Management Fees</span>
                            <strong className={`qr-price d-block ${management_payment.toString().length > 6 ? 'font_60' : ''}`}>
                              {management_payment ? addCommas(management_payment) : 0}<sup>QR</sup>
                            </strong>
                          </div>
                          <div className="qr-wrap sub-payment">
                            <span className="info-txt d-block">Loan Care Fees</span>
                            <strong className={`qr-price d-block ${loan_care_payment.toString().length > 6 ? 'font_60' : ''}`}>
                              {loan_care_payment ? addCommas(loan_care_payment) : 0}<sup>QR</sup>
                            </strong>
                          </div>
                        </div>

                        <RightSideBlock modules={props.modules} PageName={props.page.Pagename} />

                      </div>
                    </div>


                  </Col>
                 </div>
              </Row>
            </Container>
          </div>
        </div>

      }
    </React.Fragment>
  )
}

export default MortgageCalculator
