import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useCallback } from "react"
import {
    Button,
    Navbar,
    Nav,
    Form,
    Container,
    FormControl,
    Row,
    Col,
} from "react-bootstrap"
import GoogleReviews from "./GoogleReviews/GoogleReviews"
import OurTeam from "./OurTeam/OurTeam"
import googleReview2 from "../../images/home/google-reviews-2.svg"
import "../ContactUs/ContactUs.scss"
import wordsToNumbers from 'words-to-numbers';

// styles

// markup
const Reviews = (props) => {

    //console.log("Modules", props.Modules);
    var testimonial_count = props.testimonials ? props.testimonials.length : 0;
    var rating_count = 0;
    props.testimonials && props.testimonials.length > 0 && props.testimonials.map((item, ind)=>{
        rating_count += parseInt(wordsToNumbers(item.starRating));
    })
    var rating_avg = rating_count/testimonial_count;



    return (

        <React.Fragment>
            <div className="">

                {
                    props.Modules && props.Modules.length > 0 && <>
                    {/* ======= Our Team ====== */}
                    {/* {props.Modules[2].Select_Collection === "Customer_Reviews" && <OurTeam Title={props.Modules[1].Title} Content={props.Modules[1].Content} />} */}

                    {/* ======= Google Reviews ====== */}
                    {props.Modules[2].Select_Collection === "Google_Reviews" && <GoogleReviews Title={props.Modules[1].Title} Content={props.Modules[1].Content} testimonials={props.testimonials} isPage={true}/>}
                    </>
                }
                
            </div>
        </React.Fragment>
    )
}

export default Reviews